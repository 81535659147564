.header-top-01 {
	border-bottom: 1px solid var(--#{$prefix}gray-300);
	.nav-link {
        color: var(--#{$prefix}gray-800);
        &:hover {
            color: var(--#{$prefix}gray-900);
        }
    }
    .navbar-transparent.navbar-light:not(.headroom--not-top) & {
    	color: rgba(var(--#{$prefix}white-rgb), 0.8);
    	border-bottom: 1px solid rgba(var(--#{$prefix}white-rgb), 0.1);
        .nav-link {
            color: rgba(var(--#{$prefix}white-rgb), 0.8);
            &:hover {
                color: var(--#{$prefix}white);
            }
        }
    }
}


.header-top-light {
    .nav-link {
        color: rgba(var(--#{$prefix}white-rgb), 0.8);
        &:hover {
            color: var(--#{$prefix}white);
        }
    }
}
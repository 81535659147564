// Header Button
.header-btn {
    border: 1px solid var(--#{$prefix}primary);
    color: var(--#{$prefix}primary);
    background: transparent;
    &:hover {
        background: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
    }
    .navbar-transparent.navbar-light:not(.headroom--not-top) & {
        border: 1px solid var(--#{$prefix}white);
        color: var(--#{$prefix}white);
        background: transparent;
        &:hover {
            background: var(--#{$prefix}white);
            color: var(--#{$prefix}dark);
        }
    }
}


.navbar {
    .header-search {
        .dropdown-toggle {
            border: none;
            background: none;
            width: 40px;
            height: 40px;
            line-height: 1;
            font-size: 23px;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            outline: none !important;
            &:after {
                display: none;
            }
        }
        .dropdown-menu {
            width: 100vw;
            right: 0;
            left: auto;
            margin: 0;
            padding: 14px;
            background: var(--#{$prefix}body-bg);
            border: 1px solid var(--#{$prefix}gray-300);
            border-radius: 0;
        }
    }
    .header-user {
        .dropdown-toggle {
            border: none;
            background: none;
            width: 40px;
            height: 40px;
            line-height: 1;
            font-size: 23px;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            outline: none !important;
            border-radius: 50%;
            &:after {
                display: none;
            }
            .navbar-light & {
                color: $navbar-light-color;
                border: 1px solid $navbar-light-disabled-color;
            }
        }
    }
}


.header-blur-light {
    background: rgba(var(--#{$prefix}body-bg-rgb), 0.10) !important;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    &.headroom--not-top {
        background: rgba(var(--#{$prefix}body-bg-rgb), 0.80) !important;
    }
}


// navbr link
.main-header {
    .navbar {
        .nav-link {
            --bs-nav-link-font-weight: #{$nav-link-weight};
        }
    }
}
.hover-top {
	transition: ease-in-out all 0.35s;
    transform: translateY(0);
	&:hover {
		transform: translateY(-10px);
	}
}

.hover-top-in {
     .hover-top--in {
        transition: ease-in-out all 0.35s;
        position: relative;
        top: 0;
    }
    &:hover {
        .hover-top--in {
            top: -10px;
        }
    }
}


.hover-scale {
    .hover-scale-in {
        overflow: hidden;
        img {
            transition: ease all 0.35s;
            transform: scale(1);
        }
    }
    &:hover {
        .hover-scale-in {
            img {
                transform: scale(1.05);
            }
        }   
    }
}

.hover-opacity-1 {
    .hover-opacity-in {
        opacity: 0;
        transition: ease all 0.35s;
    }
    &:hover {
        .hover-opacity-in {
            opacity: 1;
        }
    }
}

.arrow-hover {
    position: relative;
    .arrow-icon {
        position: absolute;
        opacity: 0;
        top: 0;
        bottom: 0;
        right: 30px;
        margin: auto;
        transition: ease all 0.35s;
    }
    &:hover {
        .arrow-icon {
            opacity: 1;
            right: 15px;
        }
    }
}


// Hover background
.theme-hover-bg {
    &:hover {
        background: var(--#{$prefix}primary) !important;
        --#{$prefix}gray-100: var(--#{$prefix}white);
        h6 {
            color: var(--#{$prefix}white);
        }
        p {
            color: var(--#{$prefix}white);
        }
    }
}


.hover-scale-box {
    transition: ease all 0.35s;
    transform: scale(1);   
    &:hover {
        transform: scale(1.05);   
    }
}

.hover-bottom-show {
    position: relative;
    overflow: hidden;
    .hover-bottom-show-in {
        position: absolute;
        bottom: -50%;
        left: 0;
        right: 0;
        z-index: 1;
        transition: ease all 0.35s;
        opacity: 0;
    }
    &:hover {
        .hover-bottom-show-in {
            bottom: 0;
            opacity: 1;
        }
    }
}

// Grayscale Hover
.grayscale-hover {
    img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: .7;
        transition: ease all 0.35s;
    }
    &:hover {
        img {
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
            opacity: 1;
        }
    }
}


.flip-box {
    position: relative;
    overflow: hidden;
    .flip-box-img {
        transition: ease all 0.35s;
        transform: rotateY(0deg);
    }
    .flip-box-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: ease all 0.35s;
        transform: rotateY(180deg);
    }
    &:hover {
        .flip-box-img {
            transform: rotateY(180deg);
            opacity: 0;
        }
        .flip-box-content {
            opacity: 1;
            transform: rotateY(0deg);
        }
    }
}

.line-hover {
    position: relative;
    overflow: hidden;
    &:before,
    &:after {
        content:"";
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        transition: ease all 0.3s;
        pointer-events: none;
    }
    &:before {
        top: 0;
        left:0;
        border-left: 5px solid var(--#{$prefix}primary);
        border-top: 5px solid var(--#{$prefix}primary);
    }
    &:after {
        bottom: 0;
        right: 0;
        border-right: 5px solid var(--#{$prefix}primary);
        border-bottom: 5px solid var(--#{$prefix}primary);
    }
    &:hover {
        &:after,
        &:before {
            opacity: 1;
            width: 100%;
            height: 100%;
        }
    }
}

.feature-hover-1 {
    position: relative;
    overflow: hidden;
    .feature-content {
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        padding: 10%;
        opacity: 0;
        transition: ease all 0.35s;
    }
    &:hover {
        .feature-content {
            opacity: 1;
            left: 0;
        }
    }
}

.feature-hover-2 {
    position: relative;
    overflow: hidden;
    .feature-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 8%;
        .btn-bar {
            position: relative;
            margin-bottom: -30px;
            opacity: 0;
            transition: ease all 0.35s;
        }
    }
    &:hover {
        .feature-content {
            .btn-bar {
                opacity: 1;
                margin-bottom: 0;
            }
        }
    }
}

.feature-hover-3 {
    position: relative;
    overflow: hidden;
    .feature-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 8%;
        padding-top: 12%;
        margin-bottom: -30px;
        opacity: 0;
        background: rgba(var(--#{$prefix}dark-rgb), 0.6);
        transition: ease all 0.35s;
    }
    &:hover {
        .feature-content {
            opacity: 1;
            padding-top: 8%;
        }
    }
}

.feature-box-1 {
    position: relative;
    &:not(.last) {
        &:after {
            content: "";
            position: absolute;
            top: 24%;
            width: 60px;
            right: -30px;
            height: 1px;
            background: var(--#{$prefix}black);
            opacity: 0.2;
            z-index: 1;
        }
    }
    @include media-breakpoint-down(lg) {
      &[class*="col-lg"]:nth-child(2n + 2) {
        &:after {
            opacity: 0;
        }
      }
    }
    @include media-breakpoint-down(md) {
      &[class*="col-md"] {
        &:after {
            display: none;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      &[class*="col-sm"] {
        &:after {
            display: none;
        }
      }
    }
}

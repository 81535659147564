// Header Fixed Effect
.main-header {
    transition: ease transform 0.35s, ease background 0.35s;
    &.headroom--unpinned {
        transform: translateY(-100%);
    }
    &.headroom--pinned {
        transform: translateY(0);
    }

    &.default-fixed-header {
        &.headroom--unpinned {
            transform: translateY(0);
            box-shadow: $box-shadow-sm;
        }
    }
}

// Header Scroll Color
.main-header {

        &.headroom--not-top {
            background-color: var(--#{$prefix}body-bg);
        }
        &.headroom--pinned.headroom--not-top {
            box-shadow: $box-shadow-sm;
        }
    
}
// Btn Mode
.btn-mode {
    --#{$prefix}btn-bg: var(--#{$prefix}emphasis-color);
    --#{$prefix}btn-color: var(--#{$prefix}tertiary-bg);
    &.active,
    &:focus,
    &:active,
    &:hover {
        background: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
    }
}

// Btn Mode
.btn-outline-mode {
    --#{$prefix}btn-border-width: 1px;
    --#{$prefix}btn-border-color: var(--#{$prefix}emphasis-color);
    --#{$prefix}btn-hover-border-color: transparent;
    --#{$prefix}btn-bg: var(--#{$prefix}body-bg);
    
    --bs-btn-active-color:var(--#{$prefix}white);
    --bs-btn-active-border-color:transparent;
    color: var(--#{$prefix}mode);
    &.active,
    &:focus,
    &:active,
    &:hover {
        background: var(--#{$prefix}primary) !important;
        border-color: transparent !important;
        color: var(--#{$prefix}white) !important;
    }
}

@each $color, $value in $theme-colors {
    .btn-#{$color}-light {
        --#{$prefix}btn-bg: rgba(#{to-rgb($value)}, 0.2);
        --#{$prefix}btn-color: rgb(#{to-rgb($value)});
        --#{$prefix}btn-hover-color: #{$white};
        --#{$prefix}btn-hover-bg: #{$value};
        --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($value)};
        --#{$prefix}btn-border-width: 0;
    }
}


.btn {
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.show {
        box-shadow: 0 .25rem .25rem .125rem rgba(var(--#{$prefix}btn-focus-shadow-rgb), .1),
        0 .375rem .75rem -.125rem rgba(var(--#{$prefix}btn-focus-shadow-rgb), .4);
    }
}



.btn-white {
    --bs-btn-border-color: var(--#{$prefix}gray-400);
}


.btn-icon {
    .btn-inner--text {
        &:not(:last-child) {
            margin-right: .5em;
        }

        &:not(:first-child) {
            margin-left: .5em;
        }
    }
}

.btn {

    .spinner-grow-sm,
    .spinner-border-sm {
        vertical-align: middle;
    }

    .btn-inner--text {
        &:not(:last-child) {
            margin-right: .5em;
        }

        &:not(:first-child) {
            margin-left: .5em;
        }
    }
}
.footer {
	.footer-logo-light{
	  display: none !important;
	}

	[data-bs-theme="dark"] & {
	  .footer-logo-light{
	    display: inline-block !important;
	  }
	  .footer-logo-dark{
	    display: none !important;
	  }
	}
}

.footer {
    .footer-title-01 {
		text-transform: uppercase;
		font-weight: 300;
		font-size: 13px;
		letter-spacing: 2px;
		margin-bottom: 30px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 20px;
		}
    }
    .footer-title-02 {
		font-size: 18px;
		margin-bottom: 30px;
		@include media-breakpoint-down(lg) {
			margin-bottom: 20px;
		}
    }
    .footer-link-01 {
    	li {
    		+ li {
    			padding-top: 15px;
    			@include media-breakpoint-down(lg) {
    				padding-top: 8px;
    			}
    		}
    	}
    	a {
    		position: relative;
    		display: inline-block;
    		vertical-align: top;
    		&:after {
    			content:"";
    			position: absolute;
    			bottom: 0;
    			left: auto;
    			right: 0;
    			width: 0px;
    			height: 1px;
    			transition: ease all 0.35s;
    			background: currentColor;
    		}
    		&:hover {
    			&:after {
    				left: 0;
	    			right: auto;
	    			width: 100%;
    			}
    		}
    	}
    }
}
// Drop Down
.px-dropdown-toggle {
    position: absolute;
    color: $navbar-light-color;
    padding: 0.9rem 0.6rem;
    line-height: 1;
    top: 0;
    right: 0;

    &.open {
        &:before {
            content: "\f286";
        }
    }
}


.navbar-toggler {
    border: none;
    padding: 0;
    width: 40px;
    height: 40px;
    position: relative;
    .toggler-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 30px;
        box-shadow: 0 0 0 1px currentColor, 0px -10px 0 1px currentColor, 0 10px 0 1px currentColor;
        height: 0;
    }
}


// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        // stylelint-disable-next-line scss/selector-no-union-class-name
        &#{$infix} {
            @include media-breakpoint-down($next) {
                .navbar-collapse {
                    position: absolute;
                    top: 100%;
                    left: 0px;
                    width: 100%;
                    background: var(--#{$prefix}body-bg);
                    padding: 8px 15px;
                    max-height: 80vh;
                    overflow-y: auto;
                    z-index: 11;
                    .navbar-dark & {
                        background: var(--#{$prefix}dark);
                    }
                }

                .dropdown-menu {
                    border: 1px solid var(--#{$prefix}gray-300);
                    background: var(--#{$prefix}body-bg);

                    .dropdown-menu {
                        background: var(--#{$prefix}body-bg);
                        margin: 0 12px;
                    }
                }
            }

            @include media-breakpoint-up($next) {
                >.container {
                    position: relative;
                }

                .px-dropdown-toggle {
                    display: none;
                }

                .header-search {
                    position: relative;
                    width: 100%;

                    .dropdown-toggle {
                        display: none;
                    }

                    .dropdown-menu {
                        position: relative;
                        display: block;
                        top: auto;
                        z-index: auto;
                        padding: 0;
                        margin: 0;
                        width: auto;
                        border: none;
                        background: transparent;
                    }

                    .input-group-text {
                        padding: 0 12px;
                        border-left: none;
                        background: none;
                    }
                }

                .navbar-nav {
                    .nav-link {
                        white-space: nowrap;
                    }

                    >.nav-item {
                        &.dropdown {
                            >.nav-link {
                                &:after {
                                    content: "\f282";
                                    display: inline-block;
                                    font-family: bootstrap-icons !important;
                                    font-style: normal;
                                    font-weight: normal !important;
                                    font-variant: normal;
                                    text-transform: none;
                                    line-height: 1;
                                    vertical-align: -.125em;
                                    -webkit-font-smoothing: antialiased;
                                    -moz-osx-font-smoothing: grayscale;
                                    color: currentColor;
                                    font-size: 75%;
                                    margin-left: 7px;
                                }
                            }
                        }

                        &:hover {
                            >.dropdown-menu {
                                opacity: 1;
                                pointer-events: auto;
                            }
                        }
                    }

                    .px-mega-dropdown {
                        position: static;
                    }

                    // Dropdown Menu
                    .dropdown-menu {
                        display: block;
                        opacity: 0;
                        pointer-events: none;
                        transition: ease all 0.35s;
                        margin: 0;
                        left: 50%;
                        transform: translate(-50%, 0);
                        box-shadow: $box-shadow-lg;
                        border-top: 2px solid var(--#{$prefix}primary);

                        &.left {
                            left: 0;
                            transform: translate(0, 0);
                        }

                        &.right {
                            left: auto;
                            right: 0;
                            transform: translate(0, 0);
                        }

                        // Sub Menu
                        .dropdown {
                            >.dropdown-item {
                                position: relative;
                                padding-right: 18px;

                                &:after {
                                    content: "\f285";
                                    display: inline-block;
                                    font-family: bootstrap-icons !important;
                                    font-style: normal;
                                    font-weight: normal !important;
                                    font-variant: normal;
                                    text-transform: none;
                                    line-height: 1;
                                    vertical-align: -.125em;
                                    -webkit-font-smoothing: antialiased;
                                    -moz-osx-font-smoothing: grayscale;
                                    color: currentColor;
                                    font-size: 85%;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                    right: 10px;
                                    height: 10px;
                                }
                            }

                            &:hover {
                                .dropdown-menu-sub {
                                    opacity: 1;
                                    pointer-events: auto;
                                }
                            }
                        }

                        .dropdown-menu-sub {
                            position: absolute;
                            top: 0;
                            left: 100%;
                            opacity: 0;
                            pointer-events: none;
                            transform: translate(0, 0);

                            &.right {
                                left: auto;
                                right: 100%;
                            }
                        }

                        // Menu size
                        &.dropdown-menu-md {
                            min-width: $px-dropdown-menu-md;
                        }

                        &.dropdown-menu-lg {
                            min-width: $px-dropdown-menu-lg;
                        }

                        &.dropdown-menu-xl {
                            min-width: $px-dropdown-menu-xl;
                        }

                        &.px-mega-menu {
                            width: 100%;
                        }

                        // Dropdown Item
                        .dropdown-item {
                            font-weight: $nav-link-weight;
                            padding: 0.4rem 1rem;
                        }
                    }
                }
            }
        }
    }
}



.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        // stylelint-disable-next-line scss/selector-no-union-class-name
        &#{$infix} {
            @include media-breakpoint-up($next) {
            }
        }
    }
}